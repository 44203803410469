import Shorts from "./Shorts";
import { LuVideo, LuImage, LuCamera } from "react-icons/lu";
import { AiOutlineEdit } from "react-icons/ai";
import { FiImage } from "react-icons/fi";
import CreatePost from "./CreatePost";

export default function Header() {
  return (
    <header>
      {/* <Shorts /> */}
      <CreatePost />
    </header>
  )
}
