import React from 'react'

export default function ErrorPage() {
  return (
    <>
    <main><p>nothing found</p>
    <a href="/" >Get back to safety</a>
    </main>
    
    </>
  )
}
